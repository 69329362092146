import React from "react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";

const Pagination: React.FC<{ changePage: Function; currentPage: number; dataPerPage: number; totalData: number; utils: any }> = ({ changePage, currentPage, dataPerPage, totalData, utils }) => {
    const totalPages = Math.ceil(totalData / dataPerPage);
    const centerPages = [];
    var pagesBefore = currentPage - 1;
    var pagesAfter = currentPage + 1;
    if (currentPage === totalPages) pagesBefore = pagesBefore - 2;
    else if (currentPage === totalPages - 1) pagesBefore = pagesBefore - 1;
    if (currentPage === 1) pagesAfter = pagesAfter + 2;
    else if (currentPage === 2) pagesAfter = pagesAfter + 1;
    for (let pageLength = pagesBefore; pageLength <= pagesAfter; pageLength++) {
        if (pageLength > totalPages) continue;
        if (pageLength === 0) pageLength = pageLength + 1;
        if (pageLength < 1) break;
        centerPages.push(pageLength);
    }
    return (
        <nav className="pagination">
            <ul>
                {currentPage > 1 && (
                    <li className="button prev" onClick={() => changePage(currentPage - 1)}>
                        <span className="button-icon">
                            <BiLeftArrow />
                        </span>
                        <span className="button-text">Prev</span>
                    </li>
                )}
                {currentPage > 2 && totalPages > 4 && (
                    <li className="numb" onClick={() => changePage(1)}>
                        <span>1</span>
                    </li>
                )}
                {currentPage > 3 && totalPages > 5 && (
                    <li className="dots">
                        <span>...</span>
                    </li>
                )}
                {centerPages.map((value) => (
                    <li key={value} className={`numb ${value === currentPage ? "active" : ""}`} onClick={() => changePage(value)}>
                        <span>{value}</span>
                    </li>
                ))}

                {currentPage < totalPages - 2 && totalPages > 5 && (
                    <li className="dots">
                        <span>...</span>
                    </li>
                )}
                {currentPage < totalPages - 1 && totalPages > 4 && (
                    <li className="numb" onClick={() => changePage(totalPages)}>
                        <span>{totalPages}</span>
                    </li>
                )}
                {currentPage < totalPages && (
                    <li className="button next" onClick={() => changePage(currentPage + 1)}>
                        <span className="button-text">Next</span>
                        <span className="button-icon">
                            <BiRightArrow />
                        </span>
                    </li>
                )}
                {/* <li className="button prev">
                    <span className="button-icon">
                        <BiLeftArrow />
                    </span>
                    <span className="button-text">Prev</span>
                </li>
                <li className="numb active">
                    <span>1</span>
                </li>
                <li className="numb">
                    <span>2</span>
                </li>
                <li className="dots">
                    <span>...</span>
                </li>
                <li className="numb">
                    <span>4</span>
                </li>
                <li className="numb">
                    <span>5</span>
                </li>
                <li className="dots">
                    <span>...</span>
                </li>
                <li className="numb">
                    <span>7</span>
                </li>
                <li className="button next">
                    <span className="button-text">Next</span>
                    <span className="button-icon">
                        <BiRightArrow />
                    </span>
                </li> */}
            </ul>
        </nav>
    );
};

export default Pagination;
