import React from "react";

import { BiMoon, BiSun, BiCopyright } from "react-icons/bi";
import { FaDiscord, FaGithub, FaTwitter, FaYoutube } from "react-icons/fa";

const Footer: React.FC<{ utils: any }> = ({ utils }) => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="content-footer">
                    <div className="profil">
                        <a className="logo-area font-thin" href="/">
                            <img src={`${process.env.PUBLIC_URL}/media/svg/logo02.svg`} alt="logo" />
                            <span className="logo-name">Titan Studios</span>
                        </a>
                        <div className="description-area">
                            <p>Dedicated to providing you with high-quality products that are sure to bring joy to people all over the world.</p>
                        </div>
                        <div className="social-media flex">
                            <a href="https://discord.gg/j5pkCEff8P" rel="noreferrer" target="_blank">
                                <FaDiscord />
                            </a>
                            <a href="https://twitter.com/DeathStormTitan" rel="noreferrer" target="_blank">
                                <FaTwitter />
                            </a>
                            <a href="https://youtube.com/@DeathStormTitan" rel="noreferrer" target="_blank">
                                <FaYoutube />
                            </a>
                            <a href="https://github.com/DSTitan" rel="noreferrer" target="_blank">
                                <FaGithub />
                            </a>
                        </div>
                    </div>
                    <div className="service-area">
                        <ul className="service-header">
                            <li className="service-name">Product</li>
                            <li>
                                <a href="https://www.titanbot.net" rel="noreferrer" target="_blank">
                                    Titan-Bot
                                </a>
                            </li>
                            <li>
                                <a href="/commissions">Commissions</a>
                            </li>
                            <li>
                                <a href="https://dstitan.redbubble.com" rel="noreferrer" target="_blank">
                                    Merch
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div className="footer-bottom">
                    <div className="copyright">
                        <BiCopyright />
                        <span>2023 Titan Studios ▪ Made For You</span>
                    </div>
                    <div className="tou">
                        {/* <a href="/terms">Terms of Use</a>
                        <a href="/privacy">Privacy Notice</a> */}
                        {/* <Translate content="components.footer.tou.link3" component="a" href="/cookie" /> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
