import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import AppComponent from "@app@::@component";
import AppUtil from "@app@::@util";

import "@app@::@style";

const App: React.FC = () => {
    AppUtil.InitializeBody();
    return (
        <Router>
            <AppComponent.Pages />
        </Router>
    );
};

export default App;
