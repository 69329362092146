const List = {
    team: [
        {
            id: "625487161092866107",
            name: "Titan",
            username: "DeathStormTitan",
            roles: [0, 2, 3, 5, 6, 7, 8, 16, 12, 18],
            landscape: true,
            background: "bg-gradient-to-r from-[#0c022c] to-[#9963dd]",
        },
        {
            id: "320112498156109824",
            name: "Pancake",
            username: "PancakeUmbreIsHere",
            roles: [1, 2, 7, 8, 16, 18],
            landscape: false,
            background: "bg-gradient-to-r from-[#9963dd] to-[#0c022c]",
        },
        {
            id: "857682277257445397",
            name: "Yaboi",
            username: "Yaboi",
            roles: [5, 6, 7, 8],
            landscape: false,
            background: "bg-gradient-to-r from-[#5612d6] to-[#f9035e]",
        },
        {
            id: "686520701888495693",
            name: "Ploof",
            username: "PloofBeeb",
            roles: [2, 3, 16, 18],
            landscape: false,
            background: "bg-gradient-to-r from-[#0e0725] to-[#a106f4]",
        },
        {
            id: "772952411311439892",
            name: "Storm",
            username: "Storm",
            roles: [8, 14, 18],
            landscape: false,
            background: "bg-gradient-to-r from-[#9700d3] to-[#2400b6]",
        },
        {
            id: "1065357475894796309",
            name: "Skull",
            username: "TheDiamondSkull",
            roles: [8, 12, 16],
            landscape: false,
            background: "bg-gradient-to-r from-[#3d8bff] to-[#00ff87]",
        },
        {
            id: "824185216963837952",
            name: "Salem",
            username: "Salem",
            roles: [3, 16],
            landscape: false,
            background: "bg-gradient-to-r from-[#3c0a79] to-[#70f0a5]",
        },
        {
            id: "829964688107634688",
            name: "Rit",
            username: "Rit",
            roles: [16],
            landscape: false,
            background: "bg-gradient-to-r from-[#103783] to-[#9BAFD9]",
        },
        // {
        //     name: "Jay",
        //     username: "JayTheLonelyDemon",
        //     roles: [],
        //     landscape: true,
        //     background: "",
        // },

        // {
        //     name: "Crane",
        //     username: "CraneDragon",
        //     roles: [3],
        //     landscape: true,
        //     background: "bg-gradient-to-r from-[#3c0a79] to-[#70f0a5]",
        // },
        // {
        //     name: "Max",
        //     username: "SuperMax",
        //     roles: [],
        //     landscape: true,
        //     background: "",
        // },
    ],
    roles: [
        /*0*/ { name: "CEO ▪ Founder", info: "Provides overall direction and leadership for the company." },
        /*1*/ { name: "Co-CEO", info: "Responsible for overseeing the company's operations and strategic planning, and for working closely with the other staffs of the executive team to ensure that the company is meeting its goals and objectives." },
        /*2*/ { name: "Creative Director", info: "Responsible for overseeing the creative direction of the company's projects." },
        /*3*/ { name: "Digital Artist", info: "Creates visual assets such as graphics, animations, and models for use in digital content." },
        /*4*/ { name: "Sound Designer", info: "Creates original music and sound effects for games, apps, and other projects." },
        /*5*/ { name: "Software Developer", info: "Responsible for designing and developing software, including games, apps, and web-based applications." },
        /*6*/ { name: "UI ▪ UX Designer", info: "Responsible for designing the user interface and user experience for digital products." },
        /*7*/ { name: "Project Manager", info: "Oversees project timelines, budgets, and team staff responsibilities." },
        /*8*/ { name: "Quality Assurance Tester", info: "Tests products for bugs and ensures they meet quality standards." },
        /*9*/ { name: "Marketing Manager", info: "Responsible for promoting the company's products and services." },
        /*10*/ { name: "Sales Manager", info: "Responsible for generating revenue and managing client relationships." },
        /*11*/ { name: "Social Media Manager", info: "Manages the company's social media accounts and online presence." },
        /*12*/ { name: "Content Creator", info: "Creates written, visual, and audio content for the company's blog, social media, and other channels." },
        /*13*/ { name: "Data Analyst", info: "Uses data to inform decision-making and improve the company's products and processes." },
        /*14*/ { name: "Technical Writer", info: "Creates documentation and instructional materials for the company's products and services." },
        /*15*/ { name: "IT Specialist", info: "Provides technical support for the company's hardware and software systems." },
        /*16*/ { name: "Support", info: "Responsible for providing assistance to customers who have questions, issues, or concerns related to the company's products or services. Additionally, are responsible for creating and updating customer support documentation, such as user manuals, FAQs, and knowledge bases, to help customers find answers to common questions or issues." },
        /*17*/ { name: "Legal Counsel", info: "Provides legal advice and guidance on company operations, contracts, and intellectual property." },
        /*18*/ { name: "Community Manager", info: "Responsible for building and maintaining relationships with the company's customers or users, and for promoting engagement and interaction within the community." },
    ],
    creations: [
        { type: 0, id: "00001", name: "Titan-Bot", description: "A versatile and dynamic Discord Bot that offers a vast range of commands and engaging modules.", price: null, url: "https://www.titanbot.net", contributors: ["625487161092866107"] },
        { type: 0, id: "00006", name: "UtiliKitty", description: "Utility npm package for javascript & typescript projects.", url: "https://github.com/DSTitan/UtiliKitty", contributors: ["625487161092866107"] },
        { type: 0, id: "00004", name: "Lock Folder", description: "A quick and simple way to lock your folders on windows.", url: "https://github.com/Titan-Studios/LockFolder", contributors: ["625487161092866107"] },
        { type: 0, id: "00005", name: "Fluffy Fetcher", description: "Quickly bulk download e926 or e621 posts by provided tags.", url: "https://github.com/DSTitan/Fluffy-Fetcher", contributors: ["625487161092866107"] },
        { type: 1, id: "00002", name: "Hobkin Gloves", description: "Cool gloves for the hobkin avatar model, comes with 7 different colors.", price: "Free", url: "https://dstitan.gumroad.com/l/hobkin_gloves", contributors: ["625487161092866107"] },
        { type: 1, id: "00003", name: "Hobkin Goggles", description: "Sleek vintage googles you can add to you Hobkin avatar.", price: "Free", url: "https://dstitan.gumroad.com/l/hobkin_goggles", contributors: ["625487161092866107"] },
    ],
};

export default List;
