import React from "react";

const Loading: React.FC = () => {
    return (
        <div className="loading">
            <img src={`${process.env.PUBLIC_URL}/media/svg/loading02.svg`} alt="loading" />
        </div>
    );
};

export default Loading;
