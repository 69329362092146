import React from "react";
import { BiBox, BiBrain, BiBrush } from "react-icons/bi";

import AppComponent from "@app@::@component";
import AppData from "@app@::@data";

const Section: React.FC<{ utils: any }> = ({ utils }) => (
    <section className="content-area">
        <div className="container mx-auto min-h-screen flex items-center">
            <div className="grid gap-4">
                <div className="relative container bg-[#1E1E2E] p-10 rounded-lg drop-shadow-01">
                    <h1 className="text main-title-1 text-center" children="Hewo Squishy!" />
                    <div className="mt-3" />
                    <p className="text subtitle-4 mx-auto text-center">
                        Hoi im Titan and welcome to Titan Studios, a little development studio that I created!
                        <br />
                        <br />
                        I adore making many cute, wonderful, and helpful items for everyone to enjoy. Artwork, 3D assets, websites, apps, programming projects, and other amazing development stuff are some of the things you could see me creating; I'm constantly striving to better my abilities and make my stuff entertaining to use!
                        <br />
                        <br />
                        Hope chu enjoy my little creations!
                    </p>
                    <div className="button flex justify-center mt-5 gap-4">
                        <button className="hover01 color-primary drop-shadow-lg" onClick={() => (document.location.href = "#projects")}>
                            <span className="button-icon both">
                                <BiBox />
                            </span>
                            <span className="button-text">Projects</span>
                        </button>
                        <button className="hover01 color-primary drop-shadow-lg" onClick={() => (document.location.href = "/commissions")}>
                            <span className="button-icon both">
                                <BiBrush />
                            </span>
                            <span className="button-text">Commissions</span>
                        </button>
                    </div>
                </div>
                <img className="rounded-lg drop-shadow-01" src={`${process.env.PUBLIC_URL}/media/png/banner_low.png`} alt="" />
                {/* <div className="relative container bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01">
                    <p className="text subtitle-4 bg-fade text-center">Want to commission an amazing VRChat avatar or art from me? In need of a custom website or Discord bot?</p>
                    <p className="text subtitle-4 bg-fade text-center">
                        Contact me or a staff member in my <a href="https://discord.gg/j5pkCEff8P">Discord Server</a> and request as such!
                    </p>
                    <p className="text subtitle-4 bg-fade text-center">
                        You can find artwork I created on my <a href="https://twitter.com/DeathStormTitan">Twitter</a>.
                    </p>
                </div> */}
            </div>
        </div>
        <div className="full-divider relative my-5" />
        <div className="container mx-auto" id="projects">
            <div className="relative container bg-[#1E1E2E] p-10 rounded-lg drop-shadow-01">
                <h1 className="text title-6 text-center" children="Projects" />
                <p className="text subtitle-4 bg-fade text-center">Here you can find some of the main projects i've worked and im working on, as well as some smaller stuff i've created.</p>
                <div className="creation-card-grid flex flex-wrap gap-4 mx-auto justify-center mb-5 mt-3">{AppData.project.creations.filter((a) => a.type === 0).map((a) => CreationCard(a))}</div>
                <h1 className="text title-8 text-center" children="Assets" />
                <div className="creation-card-grid flex flex-wrap gap-4 mx-auto justify-center mb-5 mt-3">{AppData.project.creations.filter((a) => a.type === 1).map((a) => CreationCard(a))}</div>
            </div>
        </div>
        <div className="full-divider relative my-10" />
        {/* <div className="container mx-auto">
            <div className="relative container bg-[#1E1E2E] p-10 rounded-lg drop-shadow-01">
                <h1 className="text title-6" children="About" />
                <p className="text subtitle-4 bg-fade mt-3 mx-auto">
                    Welcome to Titan Studios, where creativity and innovation come together to produce amazing digital content! As a sole trader, I, Titan, am dedicated to creating high-quality art, 3D content, avatars, websites, apps, and programming projects that are sure to bring joy to people all over the world.
                    <br />
                    <br />
                    At Titan Studios, I take great pride in being an owner-operated company. This means that I, as the most passionate person about our products, am also the main producer of the goods. This level of dedication and attention to detail allows me to create products that truly stand out from the crowd.
                    <br />
                    <br />
                    My primary objective is to provide you with awesome things to enjoy. From my stunning art and graphics to my immersive apps, I strive to make your experience with my products as joyful and memorable as possible. Whether you're looking for something to pass the time or want to explore new worlds and experiences, Titan Studios has something for everyone.
                    <br />
                    <br />
                    So why not join me on this exciting journey and discover what makes Titan Studios so special? With my talent and passion for creativity, you're sure to find something that will leave you feeling amazed, inspired, and joyful!
                </p>
            </div>
        </div>
        <div className="full-divider relative my-10" /> */}
        {/* <div className="container mx-auto" id="our-team">
            <div className="relative container bg-[#1E1E2E] p-10 rounded-lg drop-shadow-01">
                <h1 className="text title-6" children="Our Team" />
                <p className="text subtitle-4 bg-fade mt-3 mx-auto">Meet the talented individuals who make up our team! We believe that our team is our greatest asset, and we're proud to have a diverse group of professionals with unique skills and experiences. From developers and designers to support specialists, we're all passionate about creating amazing products and delivering exceptional service. You can also get in touch with us by joining our discord server.</p>
                <div className="button flex justify-center my-4 mb-6 screen">
                    <button className="hover01 color-primary drop-shadow-lg" onClick={() => window.open("https://discord.gg/j5pkCEff8P")}>
                        <span className="button-icon both">
                            <BiHeart />
                        </span>
                        <span className="button-text">Discord Server</span>
                    </button>
                </div>
                <div className="staff-card-grid flex flex-wrap gap-4 mt-5 mx-auto justify-center">
                    {AppData.project.team.map((staff) => (
                        <div children={StaffCard(staff)} />
                    ))}
                </div>
            </div>
        </div>
        <div className="full-divider relative my-10" /> */}
    </section>
);

const StaffCard = (staff: any) => (
    <div className={`staff-card ${staff.background}`}>
        <div className="images">
            <div className="banner ">
                <img className={staff.landscape ? "landscape" : "portrait"} src={`${process.env.PUBLIC_URL}/media/png/team/${staff.username.toLowerCase()}_banner.png`} alt="" />
            </div>
            <div className="avatar">
                <img className={staff.landscape ? "landscape" : "portrait"} src={`${process.env.PUBLIC_URL}/media/png/team/${staff.username.toLowerCase()}_avatar.png`} alt="" />
            </div>
        </div>
        <div className="details">
            <div className="name">{staff.name}</div>
            <div className="username">@{staff.username}</div>
            <div className="roles">
                {staff.roles.map((skill: any) => (
                    <AppComponent.Tooltip content={AppData.project.roles[skill].info} children={<div className="role w-fit">{AppData.project.roles[skill].name}</div>} />
                ))}
            </div>
        </div>
    </div>
);

const CreationCard = (creation: any) => (
    <div className="creation-card" onClick={() => window.open(creation.url)}>
        <div className="image">
            <img src={`${process.env.PUBLIC_URL}/media/png/creations/${creation.id}_icon.png`} alt="" />
        </div>
        {creation.price && <div className="price">{creation.price}</div>}
        <div className="details">
            <div className="name">{creation.name}</div>
            <div className="description">{creation.description}</div>
        </div>
        {/* <div className="icon">
            <AppComponent.Tooltip
                content={
                    <div className="grid gap-2">
                        {creation.contributors.map((id: any) => {
                            const staff = AppData.project.team.find((a) => a.id === id);
                            return staff ? (
                                <div className="flex gap-2 items-center">
                                    <img className="h-[30px] w-[30px] rounded-full" src={`${process.env.PUBLIC_URL}/media/png/team/${staff.username.toLowerCase()}_avatar.png`} alt="" />
                                    <p>@{staff.username}</p>
                                </div>
                            ) : (
                                <></>
                            );
                        })}
                    </div>
                }
                children={
                    <div className="contributors">
                        <BiBrain color="Gold" />
                    </div>
                }
            />
        </div> */}
    </div>
);

const Components = { Section };

export default Components;
