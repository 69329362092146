import React from "react";
import Tippy from "@tippyjs/react";
import { useSpring, motion } from "framer-motion";

const Tooltip: React.FC<{ content: any; children: any; placement?: any; delay?: any }> = ({ content, children, placement, delay }) => {
    const springConfig = { damping: 15, stiffness: 300 };
    const initialScale = 0.5;
    const opacity = useSpring(0, springConfig);
    const scale = useSpring(initialScale, springConfig);

    function onMount() {
        scale.set(1);
        opacity.set(1);
    }

    function onHide({ unmount }: any) {
        const cleanup = scale.onChange((value) => {
            if (value <= initialScale) {
                cleanup();
                unmount();
            }
        });

        scale.set(initialScale);
        opacity.set(0);
    }

    return (
        <Tippy
            delay={delay ? delay : 100}
            animation={true}
            onMount={onMount}
            onHide={onHide}
            placement={placement ? placement : "top"}
            render={(attrs) => (
                <motion.div className="tooltip" style={{ scale, opacity }} {...attrs}>
                    {content}
                </motion.div>
            )}
            children={children}
        />
    );
};

export default Tooltip;
