import React from "react";
import { Routes, Route } from "react-router-dom";
import Counterpart from "counterpart";

import AppData from "@app@::@data";
import AppUtil from "@app@::@util";

import Loading from "@components@::/page/loading.component";
import Header from "@components@::/page/header.component";
import Footer from "@components@::/page/footer.component";
import Pagination from "@components@::/page/pagination.component";
import Tooltip from "@components@::/page/tooltip.component";
import Animation from "@components@::/page/animation.component";
import ReadMore from "@components@::/page/readmore.component";

import HomeRoute from "@route@::@home";
import CommissionRoute from "@route@::@commissions";

Counterpart.setLocale("en");

const Pages: React.FC = () => {
    const utils = { use: AppUtil.UseCases() };

    return (
        <>
            <Routes>
                <Route element={<HomeRoute.Page utils={utils} />} path={HomeRoute.Path} caseSensitive={HomeRoute.Options.caseSensitive} />
                <Route element={<CommissionRoute.Page utils={utils} />} path={CommissionRoute.Path} caseSensitive={CommissionRoute.Options.caseSensitive} />
            </Routes>
        </>
    );
};

const Index = { Pages, Loading, Header, Footer, Pagination, Tooltip, Animation, ReadMore };

export default Index;
