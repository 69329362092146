import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

const InitializeBody = () => {
    for (let blockedEvent of ["contextmenu", "dragstart"]) {
        document.addEventListener(blockedEvent, function (event) {
            event.preventDefault();
            return false;
        });
    }
};

const UseCases = () => {
    const Navigate = useNavigate();
    const SearchParams = useSearchParams();
    const Match = useMatch;

    return { Navigate, Match, SearchParams };
};

const Utils = { InitializeBody, UseCases };

export default Utils;
