import React from "react";
import { BiBot, BiImage, BiMask, BiPencil, BiWindow } from "react-icons/bi";
import { FaDiscord } from "react-icons/fa";

import AppComponent from "@app@::@component";

const commissionDetails = {
    artwork: {
        main: [
            {
                name: "Line Art",
                details: "Line artwork of requested character(s).",
                tableHead: ["Artwork", "Price"],
                options: [
                    ["Icon", "$4.00"],
                    ["Bust", "$5.00"],
                    ["Semi Body", "$10.00"],
                    ["Full Body", "$14.00"],
                ],
            },
            {
                name: "Flat Color",
                details: "Line artwork of requested character(s) thats colored with no shade.",
                tableHead: ["Artwork", "Price"],
                options: [
                    ["Icon", "$8.00"],
                    ["Bust", "$10.00"],
                    ["Semi Body", "$20.00"],
                    ["Full Body", "$28.00"],
                ],
            },
            {
                name: "Cartoon Shade",
                details: "",
                tableHead: ["Artwork", "Price"],
                options: [
                    ["Icon", "$12.00"],
                    ["Bust", "$15.00"],
                    ["Semi Body", "$30.00"],
                    ["Full Body", "$42.00"],
                ],
            },
            {
                name: "Render Shade",
                details: "",
                tableHead: ["Artwork", "Price"],
                options: [
                    ["Icon", "$15.00"],
                    ["Bust", "$20.00"],
                    ["Semi Body", "$40.00"],
                    ["Full Body", "$56.00"],
                ],
            },
        ],
        emojiSheet: [
            {
                name: "Emoji Sheet",
                details: "",
                tableHead: ["Sheet Size", "Price", "Rate"],
                options: [
                    ["Pack of 3", "$18.00", "$6.00 per Emoji"],
                    ["Pack of 8", "$40.00", "$5.00 per Emoji"],
                    ["Pack of 14", "$56.00", "$4.00 per Emoji"],
                    ["Pack of 25", "$75.00", "$3.00 per Emoji"],
                ],
            },
        ],
        refSheet: [
            {
                name: "Reference Sheet",
                details: "",
                tableHead: ["Sheet Tier", "Price", "View Points"],
                options: [
                    ["Basic", "$20.00", "1 Full Body"],
                    ["Intermediate", "$35.00", "1 Full Body + 1 Bust"],
                    ["Standard", "$50.00", "2 Full Body + 1 Bust"],
                    ["Premium", "$75.00", "2 Full Body + 1 Semi Body + 1 Bust"],
                    ["Deluxe", "$100.00", "2 Full Body + 1 Semi Body + 4 Expressions"],
                    ["Ultimate", "$150.00", "3 Full Body + 2 Semi Body + 1 Bust + 6 Expressions"],
                ],
            },
        ],
        background: [
            {
                name: "Background",
                details: "",
                tableHead: ["Type", "Price"],
                options: [
                    ["Transparent / Color", "Free"],
                    ["Pattern", "Starts at $0"],
                    ["Scene", "Starts at $5"],
                ],
            },
        ],
    },
};

const Section: React.FC<{ utils: any }> = ({ utils }) => {
    const activeTab = React.useState<number>(0);
    return (
        <section className="content-area min-h-screen">
            <div className="container mx-auto">
                <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto my-5">
                    <h1 className="text text-[30px] sm:text-[50px] font-02 text-center drop-shadow-xl" children="Commissions" />
                    <p className="text subtitle-4 mx-auto text-center">View information about the various commissions I offer, including details and prices.</p>
                    <div className="button mx-auto w-fit mt-4">
                        <button className="hover01 color-primary drop-shadow-lg" onClick={() => window.open("https://discord.gg/j5pkCEff8P")}>
                            <span className="button-icon both">
                                <FaDiscord />
                            </span>
                            <span className="button-text">Request Commission</span>
                        </button>
                    </div>
                </div>
                <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mb-10">
                    <div className="button flex flex-wrap justify-center gap-4">
                        <button className={`hover01 color-${activeTab[0] === 0 ? "success pointer-events-none" : "primary"} drop-shadow-lg`} onClick={() => activeTab[1](0)}>
                            <span className="button-icon both">
                                <BiPencil />
                            </span>
                            <span className="button-text">Artwork</span>
                        </button>
                        <button className={`hover01 color-${activeTab[0] === 1 ? "success pointer-events-none" : "primary"} drop-shadow-lg`} onClick={() => activeTab[1](1)}>
                            <span className="button-icon both">
                                <BiMask />
                            </span>
                            <span className="button-text">Avatar</span>
                        </button>
                        <button className={`hover01 color-${activeTab[0] === 2 ? "success pointer-events-none" : "primary"} drop-shadow-lg`} onClick={() => activeTab[1](2)}>
                            <span className="button-icon both">
                                <BiImage />
                            </span>
                            <span className="button-text">Render</span>
                        </button>
                        <button className={`hover01 color-${activeTab[0] === 3 ? "success pointer-events-none" : "primary"} drop-shadow-lg`} onClick={() => activeTab[1](3)}>
                            <span className="button-icon both">
                                <BiWindow />
                            </span>
                            <span className="button-text">Website</span>
                        </button>
                        <button className={`hover01 color-${activeTab[0] === 4 ? "success pointer-events-none" : "primary"} drop-shadow-lg`} onClick={() => activeTab[1](4)}>
                            <span className="button-icon both">
                                <BiBot />
                            </span>
                            <span className="button-text">Discord Bot</span>
                        </button>
                    </div>
                </div>
                {
                    [
                        <>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto">
                                <h1 className="text title-7 text-center drop-shadow-xl" children="Art Commissions" />
                                <p className="text text-[18px] font-02 text-center drop-shadow-xl">
                                    <span className="text-lime-300">Currently Open</span>
                                </p>
                                <p className="text subtitle-4">
                                    - Art commissions can approximately take <strong className="text-lime-400">1 day to 2 weeks</strong> to complete depending on the queue, complexity and my availability.
                                    <br />
                                    <br />- Each additional characters cost an extra <strong className="text-lime-400">75%</strong> of the base price, not including background prices.
                                    <br />
                                    <br />- Prices may increase depending on the complexity of the character or background.
                                    <br />
                                    <br />- A non-refundable deposit of <strong className="text-lime-400">50%</strong> of the total cost is required before work on the commission can begin. The final payment is due upon completion of the commission.
                                    <br />
                                    <br />- If a artwork will be used for commercial purposes this must be stated.
                                    <br />
                                    <br />- For any NSFW commissions, prices may increase, and you are required to verify your age when requesting such commissions.
                                    <br />
                                    <br />- I'm entitled to refuse any commissions that would make me uncomfortable or that I would not like to do.
                                </p>
                            </div>
                            {/* <div className="relative container w-fit mt-5 flex mx-auto gap-2">
                                <strong className="text text-[17px] text-center bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01">Discussion</strong>
                                <strong className="text text-[17px] text-center bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01">Accepted</strong>
                                <strong className="text text-[17px] text-center bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01">Sketch Delivered</strong>
                                <strong className="text text-[17px] text-center bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01">Payment Completed</strong>
                                <strong className="text text-[17px] text-center bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01">Processing</strong>
                                <strong className="text text-[17px] text-center bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01">Finished Artwork Delivered</strong>
                            </div> */}
                            <div className="relative container w-fit bg-[#1E1E2E] mt-5 p-4 rounded-lg drop-shadow-01 mx-auto">
                                <h1 className="text title-8 text-center mt-10 drop-shadow-xl" children="Pricing" />
                                <p className="text subtitle-4 mx-auto text-center">The pricing of my different types of artwork are listed in the following tables.</p>
                            </div>
                            <div className="relative container w-fit h-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-10">
                                <div className="flex flex-wrap gap-5">
                                    {commissionDetails.artwork.main.map((type) => (
                                        <div className="mx-auto">
                                            <h1 className="text text-[18px] mb-2 font-02 text-center drop-shadow-xl">{type.name}</h1>
                                            {/* <p className="text subtitle-4 mx-auto text-center">{type.details}</p> */}

                                            <div className="relative overflow-x-auto shadow-md rounded-lg">
                                                <table className="w-full text-sm text-left text-[#aeb1b8]">
                                                    <thead className="text-xs uppercase bg-[#313149] text-[#aeb0b8]">
                                                        <tr>
                                                            {type.tableHead.map((head) => (
                                                                <th scope="col" className="px-6 py-3">
                                                                    {head}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {type.options.map((option) => (
                                                            <tr className="bg-[#11111B]">
                                                                {option.map((value, index) => (
                                                                    <th scope="row" className={`px-6 py-4 font-medium ${index === 0 ? "whitespace-nowrap text-white" : ""}`}>
                                                                        {value}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex flex-wrap gap-5 mt-3">
                                    {commissionDetails.artwork.background.map((type) => (
                                        <div className="mx-auto">
                                            <h1 className="text text-[18px] mb-2 font-02 text-center drop-shadow-xl">{type.name}</h1>
                                            {/* <p className="text subtitle-4 mx-auto text-center">{type.details}</p> */}

                                            <div className="relative overflow-x-auto shadow-md rounded-lg">
                                                <table className="w-full text-sm text-left text-[#aeb1b8]">
                                                    <thead className="text-xs uppercase bg-[#313149] text-[#aeb0b8]">
                                                        <tr>
                                                            {type.tableHead.map((head) => (
                                                                <th scope="col" className="px-6 py-3">
                                                                    {head}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {type.options.map((option) => (
                                                            <tr className="bg-[#11111B]">
                                                                {option.map((value, index) => (
                                                                    <th scope="row" className={`px-6 py-4 font-medium ${index === 0 ? "whitespace-nowrap text-white" : ""}`}>
                                                                        {value}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="relative container w-fit h-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5">
                                <AppComponent.ReadMore
                                    htmlFor="artwork-list"
                                    height={464}
                                    labelDisable="Show Less"
                                    labelEnable="Show More"
                                    children={
                                        <div className="flex flex-wrap justify-center gap-4">
                                            {new Array(36).fill(0).map((v, index) => (
                                                <img key={index} src={`${process.env.PUBLIC_URL}/media/png/gallery/artwork/low/${index + 1}.png`} alt="" className="max-h-36 rounded-md" />
                                            ))}
                                        </div>
                                    }
                                />
                            </div>
                            <div className="relative container w-fit h-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-10">
                                <div className="flex flex-wrap gap-5 mb-4">
                                    {commissionDetails.artwork.refSheet.map((type) => (
                                        <div className="mx-auto">
                                            <h1 className="text text-[18px] mb-2 font-02 text-center drop-shadow-xl">{type.name}</h1>

                                            <div className="relative overflow-x-auto shadow-md rounded-lg">
                                                <table className="w-full text-sm text-left text-[#aeb0b8]">
                                                    <thead className="text-xs uppercase bg-[#313149] text-[#aeb0b8]">
                                                        <tr>
                                                            {type.tableHead.map((head) => (
                                                                <th scope="col" className="px-6 py-3">
                                                                    {head}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {type.options.map((option) => (
                                                            <tr className="bg-[#11111B]">
                                                                {option.map((value, index) => (
                                                                    <th scope="row" className={`px-6 py-4 font-medium ${index === 0 ? "whitespace-nowrap text-white" : ""}`}>
                                                                        {value}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <p className="text subtitle-4 mx-auto">
                                    - All reference sheet comes with a color palette, name and optional description/bio.
                                    <br />
                                    <br />- Prices for additional view points may be discussed, for eg. Accessories and Body parts.
                                </p>
                            </div>
                            <div className="relative container w-fit h-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5">
                                <div className="flex flex-wrap justify-center gap-4">
                                    {new Array(2).fill(0).map((v, index) => (
                                        <img src={`${process.env.PUBLIC_URL}/media/png/gallery/refsheet/pre-${index + 1}.png`} alt="" className="max-h-36 rounded-md" />
                                    ))}
                                </div>
                            </div>
                            <div className="relative container w-fit h-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto my-10">
                                <div className="flex flex-wrap gap-5">
                                    {commissionDetails.artwork.emojiSheet.map((type) => (
                                        <div className="mx-auto">
                                            <h1 className="text text-[18px] mb-2 font-02 text-center drop-shadow-xl">{type.name}</h1>
                                            {/* <p className="text subtitle-4 mx-auto text-center">{type.details}</p> */}

                                            <div className="relative overflow-x-auto shadow-md rounded-lg">
                                                <table className="w-full text-sm text-left text-[#aeb1b8]">
                                                    <thead className="text-xs uppercase bg-[#313149] text-[#aeb0b8]">
                                                        <tr>
                                                            {type.tableHead.map((head) => (
                                                                <th scope="col" className="px-6 py-3">
                                                                    {head}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {type.options.map((option) => (
                                                            <tr className="bg-[#11111B]">
                                                                {option.map((value, index) => (
                                                                    <th scope="row" className={`px-6 py-4 font-medium ${index === 0 ? "whitespace-nowrap text-white" : ""}`}>
                                                                        {value}
                                                                    </th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>,
                        <>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto">
                                <h1 className="text title-7 text-center drop-shadow-xl" children="Avatar Commissions" />
                                <p className="text text-[18px] font-02 text-center drop-shadow-xl">
                                    <span className="text-lime-300">Currently Open</span>
                                </p>
                                <p className="text subtitle-4">
                                    - Note avatars are currently only done for <strong className="text-lime-400">VRChat</strong> and I do not model <strong className="text-lime-400">Avatars</strong> from scratch at this time.
                                    <br />
                                    <br />- Avatar commissions can approximately take <strong className="text-lime-400">1 day to 3 weeks</strong> to complete depending on the queue, complexity and my availability.
                                    <br />
                                    <br />- A non-refundable deposit of <strong className="text-lime-400">50%</strong> of the total cost is required before work on the commission can begin. The final payment is due upon completion of the commission.
                                    <br />
                                    <br />- Prices may increase depending on the complexity of the avatar and what is requested.
                                    <br />
                                    <br />- For any NSFW commissions, prices may increase, and you are required to verify your age when requesting such commissions.
                                    <br />
                                    <br />- I'm entitled to refuse any commissions that would make me uncomfortable or that I would not like to do.
                                </p>
                            </div>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5">
                                <h1 className="text title-8 text-center mt-10 drop-shadow-xl" children="Pricing" />
                                <p className="text subtitle-4">
                                    The base price for a commission is <strong className="text-lime-400">$30</strong> which gives you a textured version of the provided avatar and makes it <strong className="text-lime-400">Quest</strong> and <strong className="text-lime-400">PC</strong> compatible.
                                    <br />
                                    <br />
                                    The inclusion of any external assets for the avatar, as well as any needed altering of said asset or toggles, begins at <strong className="text-lime-400">$5</strong>.
                                    <br />
                                    Custom textures for accessories may be added for an additional cost, starting at <strong className="text-lime-400">$4</strong>.
                                    <br />
                                    Editing of any existing animations or creation of new ones may increase the price of the commission.
                                    <br />
                                    <br />
                                    In the event that the commission requires any Blender work or involves making changes to the avatar within Blender, the price will increase to <strong className="text-lime-400">$100</strong> or more.
                                </p>
                            </div>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5">
                                <h1 className="text title-[17px] font-02 text-center drop-shadow-xl" children="Whats In The Zip?" />
                                <p className="text subtitle-4">
                                    - Unity project folder.
                                    <br />- Texture files.
                                    <br />- Blender & FBX files (if any).
                                </p>
                            </div>
                            <div className="relative container w-fit h-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5 mb-10">
                                <div className="flex flex-wrap justify-center gap-4">
                                    {new Array(18).fill(0).map((v, index) => (
                                        <img src={`${process.env.PUBLIC_URL}/media/png/gallery/avatar/pre-${index + 1}.png`} alt="" className="max-h-52 rounded-md" />
                                    ))}
                                </div>
                            </div>
                        </>,
                        <>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto">
                                <h1 className="text title-7 text-center drop-shadow-xl" children="Render Commissions" />
                                <p className="text text-[18px] font-02 text-center drop-shadow-xl">
                                    <span className="text-lime-300">Currently Open</span>
                                </p>
                                <p className="text subtitle-4">
                                    - The renders I create are done using blender, if the model you provide needs to be edited, the price may increase.
                                    <br />
                                    <br />- Render commissions can approximately take <strong className="text-lime-400">1 day to 3 days</strong> to complete depending on the queue, complexity and my availability.
                                    <br />
                                    <br />- Each additional characters cost an extra <strong className="text-lime-400">75%</strong> of the base price.
                                    <br />
                                    <br />- A non-refundable deposit of <strong className="text-lime-400">50%</strong> of the total cost is required before work on the commission can begin. The final payment is due upon completion of the commission. <br />
                                    <br />- Prices may increase depending on the complexity of the scene/pose and what is requested.
                                    <br />
                                    <br />- For any NSFW commissions, prices may increase, and you are required to verify your age when requesting such commissions.
                                    <br />
                                    <br />- I'm entitled to refuse any commissions that would make me uncomfortable or that I would not like to do.
                                </p>
                            </div>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5">
                                <h1 className="text title-8 text-center mt-10 drop-shadow-xl" children="Pricing" />
                                <p className="text subtitle-4">
                                    For a basic render, the starting price is <strong className="text-lime-400">$15</strong>. This includes a blank room and 2 camera angles <strong className="text-lime-400">1080p</strong> resolution.
                                    <br />
                                    <br />
                                    If you opt for a standard render, prices start at <strong className="text-lime-400">$30</strong>. You will receive a simple scene, and items of your choice, all rendered in <strong className="text-lime-400">1440p</strong> resolution.
                                    <br />
                                    <br />
                                    My premium render package starts at <strong className="text-lime-400">$50</strong>, providing you with an advanced scene, unique camera angles, intricate items, and more. These renders are typically <strong className="text-lime-400">4k or higher</strong> in resolution, ensuring the most stunning and realistic results.
                                </p>
                            </div>
                            <div className="relative container w-fit h-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5 mb-10">
                                <div className="flex flex-wrap justify-center gap-4">
                                    {new Array(22).fill(0).map((v, index) => (
                                        <img src={`${process.env.PUBLIC_URL}/media/png/gallery/render/pre-${index + 1}.png`} alt="" className="max-h-40 rounded-md" />
                                    ))}
                                </div>
                            </div>
                        </>,
                        <>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto">
                                <h1 className="text title-7 text-center drop-shadow-xl" children="Website Commissions" />
                                <p className="text text-[18px] font-02 text-center drop-shadow-xl">
                                    <span className="text-orange-300">Contact Titan</span>
                                </p>
                                <p className="text subtitle-4">
                                    - Website commissions can approximately take <strong className="text-lime-400">2 weeks or longer</strong> to complete depending on the queue, complexity and my availability.
                                    <br />
                                    <br />- Prices may increase depending on the complexity of the website, pages, components and functions.
                                    <br />
                                    <br />- A non-refundable deposit of <strong className="text-lime-400">50%</strong> of the total cost is required before work on the commission can begin. The final payment is due upon completion of the commission.
                                    <br />
                                    <br />- If the website will be used for commercial purposes this must be stated.
                                    <br />
                                    <br />- I'm entitled to refuse any commissions that would make me uncomfortable or that I would not like to do.
                                </p>
                            </div>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5 mb-10">
                                <h1 className="text title-8 text-center mt-10 drop-shadow-xl" children="Pricing" />
                                <p className="text subtitle-4">
                                    The cost of a website can vary greatly and is dependent on the specific features and requirements you need. Prices can range from a few <strong className="text-lime-400">hundred</strong> to several <strong className="text-lime-400">thousand</strong> dollars. However, my starting price for website commissions is only <strong className="text-lime-400">$100</strong> and gives you a simple website with <strong className="text-lime-400">1-2</strong> pages.
                                    <br />
                                    <br />I offer website hosting starting at <strong className="text-lime-400">$10</strong> a month. This includes reliable server space, regular backups, and security measures to ensure the safe and continuous operation of your website.
                                    <br />
                                    <br />
                                    You are entitled to <strong className="text-lime-400">three free</strong> revisions when the website commission is finished. A fee will be charged for any requested further revisions.
                                </p>
                            </div>
                        </>,
                        <>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto">
                                <h1 className="text title-7 text-center drop-shadow-xl" children="Discord Bot Commissions" />
                                <p className="text text-[18px] font-02 text-center drop-shadow-xl">
                                    <span className="text-orange-300">Contact Titan</span>
                                </p>
                                <p className="text subtitle-4">
                                    - Discord bot commissions can approximately take <strong className="text-lime-400">2 weeks or longer</strong> to complete depending on the queue, complexity and my availability.
                                    <br />
                                    <br />- Prices may increase depending on the complexity of the components, features and functions.
                                    <br />
                                    <br />- A non-refundable deposit of <strong className="text-lime-400">50%</strong> of the total cost is required before work on the commission can begin. The final payment is due upon completion of the commission.
                                    <br />
                                    <br />- If the discord bot will be used for commercial purposes this must be stated.
                                    <br />
                                    <br />- I'm entitled to refuse any commissions that would make me uncomfortable or that I would not like to do.
                                </p>
                            </div>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5 mb-10">
                                <h1 className="text title-8 text-center mt-10 drop-shadow-xl" children="Pricing" />
                                <p className="text subtitle-4">
                                    My prices are determined by the intricacy of the bot and the amount of time necessary to build it. Prices typically begin at <strong className="text-lime-400">$50</strong> and can range up to <strong className="text-lime-400">$500 or more</strong> for sophisticated projects.
                                    <br />
                                    <br />
                                    And yes I do offer hosting which starts at <strong className="text-lime-400">$10</strong> a month.
                                    <br />
                                    <br />
                                    You are entitled to <strong className="text-lime-400">three free</strong> revisions when the commission is finished. A fee will be charged for any requested further revisions.
                                    <br />
                                    <br />
                                    If you're interested in working with me or have any queries, please don't hesitate to contact me. I'm delighted to help you take your Discord server to the next level with a custom bot!
                                </p>
                            </div>
                            <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mt-5 mb-10">
                                <h1 className="text title-8 text-center mt-10 drop-shadow-xl" children="Benefits Of Custom Bots" />
                                <p className="text subtitle-4">
                                    <strong className="text-[17px] text-purple-300">~ Increased Server Engagement</strong>
                                    <br />
                                    By giving users interactive tools and commands, a custom bot may help your Discord server experience more active members.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Improved Server Management</strong>
                                    <br />
                                    You may focus on other parts of server management by having a bot handle routine duties like moderating chat, greeting new members, and maintaining responsibilities.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Customized Functionality</strong>
                                    <br />
                                    With a custom bot, you may add unique and customizable features and instructions that are tailored to your server's requirements.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Better User Experience</strong>
                                    <br />A well-designed bot may enhance members' overall experience by making it simpler for them to utilize and traverse your server.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Scalability</strong>
                                    <br />
                                    As your server grows, a custom bot can help manage the increased activity and workload, ensuring that your server remains stable and responsive.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Competitive Advantage</strong>
                                    <br />
                                    Your server may get an advantage over other servers with the use of a unique bot, which will also help it stand out and draw more members.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Increased Brand Recognition</strong>
                                    <br />
                                    Your brand's personality and aesthetic may be reflected in a bot, boosting brand exposure and recognition.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Greater Flexibility</strong>
                                    <br />A custom bot offers more flexibility and adaptability since it may be updated and changed in response to adjustments made to your server.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Cost-Effective</strong>
                                    <br />
                                    By automating operations that would normally need human work or additional staff, a custom bot may help you save time and labour.
                                    <br />
                                    <br />
                                    <strong className="text-[17px] text-purple-300">~ Unique Entertainment</strong>
                                    <br />A custom bot can provide members with unique types of amusement such as mini-games, quizzes, and other activities that will keep them engaged and interested in your server.
                                </p>
                            </div>
                        </>,
                    ][activeTab[0]]
                }
                <div className="relative container w-fit bg-[#1E1E2E] p-4 rounded-lg drop-shadow-01 mx-auto mb-10">
                    <div className="button mx-auto w-fit">
                        <button className="hover01 color-primary drop-shadow-lg" onClick={() => window.open("https://discord.gg/j5pkCEff8P")}>
                            <span className="button-icon both">
                                <FaDiscord />
                            </span>
                            <span className="button-text">Request Or Contact</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

const Components = { Section };

export default Components;
