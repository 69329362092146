import React from "react";

const ReadMore: React.FC<{ htmlFor: string; height: number; labelEnable: string; labelDisable: string; children: any; iconEnable?: any; iconDisable?: any }> = ({ htmlFor, height, labelEnable, labelDisable, iconEnable, iconDisable, children }) => {
    const readMore = React.useState(false);
    const contentHeight = React.useState(height);
    const boxHeight = React.useState(height);

    React.useEffect(() => {
        const box = document.getElementById(htmlFor + "-01");
        const content = document.getElementById(htmlFor + "-02");
        if (!box || !content) return;
        contentHeight[1](content.clientHeight);
    }, [children]);

    return (
        <div className="h-fit">
            <div id={htmlFor + "-01"} className="duration-300 overflow-hidden" style={{ height: contentHeight[0] > height ? `${boxHeight[0]}px` : "fit-content" }}>
                <div id={htmlFor + "-02"}>{children}</div>
            </div>
            {contentHeight[0] > height + 3 && (
                <div className="button flex justify-center gap-4 mt-3">
                    <button
                        className="hover02 color-secondary h-8"
                        onClick={() => {
                            readMore[1](!readMore[0]);
                            boxHeight[1](!readMore[0] ? contentHeight[0] : height);
                        }}>
                        <span className="button-text">{readMore[0] ? labelDisable : labelEnable}</span>
                        {iconEnable && iconDisable && <span className="button-icon both-1">{readMore[0] ? iconDisable : iconEnable}</span>}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ReadMore;
