import React from "react";

import AppComponent from "@app@::@component";

import HomeComponent from "@route@::@home:component";
const Path = "/";
const Options = { caseSensitive: true };

const Home: React.FC<PageProps> = ({ utils }) => {
    return (
        <AppComponent.Animation.PageFade>
            <div className="page-body parallax">
                <AppComponent.Animation.ScreenCover />
                <AppComponent.Header utils={utils} />
                <HomeComponent.Section utils={utils} />
                <AppComponent.Footer utils={utils} />
            </div>
        </AppComponent.Animation.PageFade>
    );
};

const Page = { Page: Home, Path, Options };

export default Page;
